import React from 'react';
import { BrowserRouter, Route, Router } from 'react-router-dom';

import Login from './Login';

const page1 = () => {
  return <div>page1</div>
}

const page2 = () => {
  return <div>page2
    <button>Click</button>
  </div>
}

function App() {
  return (
    <div>
      <BrowserRouter>
        <div>
          <Route path="/" exact={true} component={page1}></Route>
          <Route path="/page2" component={page2}></Route>
          <Route path="/login" component={Login}></Route>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
